<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <!-- マスタ初期登録(モジュール選択) -->
      <NavBar tittle="label.lbl_masterInit" />
      <v-form ref="form">
        <v-container fill-height>
          <v-container fluid>
            <v-row style="height: 600px; margin-top: 6px; margin-bottom: 10px">
              <v-card style="width: 100%; height: 150px; margin-left: 20px; margin-right: 20px">
                <v-card-text>
                  <p style="font-size: x-large; font-weight: bold; text-align: left">
                    モジュール選択
                  </p>
                  <p style="font-size: large; text-align: left; white-space: pre-line">
                    {{ explanation }}
                  </p>
                </v-card-text>
              </v-card>
              <v-row>
                <div class="search-row-exeBtn"></div>
                <!-- 戻るボタン -->
                <v-btn
                  color="primary"
                  class="other-btn"
                  style="margin-left: 35px"
                  @click="returnIniHome()"
                >
                  {{ $t("btn.btn_back") }}
                </v-btn>
                <!-- 次へボタン -->
                <v-btn color="primary" class="other-btn" @click="pushNext()">
                  {{ $t("btn.btn_next") }}
                </v-btn>
              </v-row>
              <v-row style="float: left; width: 100%; margin-top: 30px">
                <div style="float: left">
                  <!-- WH/WMS基本 -->
                  <v-btn
                    style="
                      float: left;
                      width: 13rem;
                      height: 7rem;
                      margin-left: 35px;
                      font-weight: bold;
                      font-size: x-large;
                      background-color: #00eeff;
                    "
                    >{{ $t("btn.btn_wmsBase") }}</v-btn
                  >
                </div>
              </v-row>
              <v-row style="float: left; width: 100%; margin-top: 20px">
                <div style="float: left">
                  <!-- PL/調達物流 -->
                  <v-btn class="module-select" id="PL" @click="selectModule('PL')">
                    {{ $t("btn.btn_procurementLogistics") }}
                  </v-btn>
                  <!-- PK/梱包 -->
                  <v-btn class="module-select" id="PK" @click="selectModule('PK')">
                    {{ $t("btn.btn_package") }}
                  </v-btn>
                  <!-- DP/流通加工 -->
                  <v-btn class="module-select" id="DP" @click="selectModule('DP')">
                    {{ $t("btn.btn_distributionProcessing") }}
                  </v-btn>
                </div>
              </v-row>
              <v-row style="float: left; width: 100%; margin-top: 20px">
                <div style="float: left">
                  <!-- WC/設備 -->
                  <v-btn class="module-select" id="WC" @click="selectModule('WC')">
                    {{ $t("btn.btn_warehouseControl") }}
                  </v-btn>
                  <!-- IE/輸出入 -->
                  <v-btn class="module-select" id="IE" @click="selectModule('IE')">{{
                    $t("btn.btn_importExport")
                  }}</v-btn>
                </div>
              </v-row>
            </v-row>
          </v-container>
          <v-navigation-drawer absolute temporary v-model="openMenu">
            <sideMenu></sideMenu>
          </v-navigation-drawer>

          <!-- 確認メッセージダイアログモーダル-->
          <SimpleDialog
            :isShow.sync="infoDialog.isOpen"
            :title="infoDialog.title"
            :message="infoDialog.message"
            :firstPageFlag="infoDialog.firstPageFlag"
            :homePageFlag="infoDialog.homePageFlag"
            :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
          />
          <ConfirmDialog
            :isShow.sync="confirmDialog.isOpen"
            :message="confirmDialog.message"
            :screenFlag="confirmDialog.screenFlag"
            :okAction="confirmDialog.okAction"
            :redMessage="confirmDialog.redMessage"
            :changeFlag="confirmDialog.changeFlag"
          />
          <v-dialog v-model="updateDialogMessage" :max-width="800">
            <!-- 警告ダイアログ -->
            <v-card>
              <v-card-title class="blue-grey lighten-3" primary-title>
                {{ $t("btn.btn_ok") }}
              </v-card-title>
              <v-card-text class="pa-4">
                <p>{{ backMessage }}</p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="updateOk"> OK </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-form>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
//import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import sideMenu from "@/components/Menu";

export default {
  name: appConfig.SCREEN_ID.P_INI_002,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    openMenu: null, // メニュー
    loadingCounter: 0, // ローディング画面表示フラグ
    explanation: "",

    clientCd: "", // 取引先コード
    inputList: [], // モジュールリスト
    addModule: [], // 追加フラグリスト
    delModule: [], // 削除モジュール
    workModuleMap: new Map(),
    compModuleMap: new Map(),

    rules: {},

    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    updateDialogMessage: false, // 戻るボタンダイアログ
    backMessage: "", // 戻るボタンダイアログメッセージ
  }),
  created() {
    // 前画面からのパラメータ設定
    this.clientCd = this.$route.params.clientCd; //取引先コード
  },

  methods: {
    /**
     * 初期値
     */
    init() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.officeCd = sessionStorage.getItem("office_cd");
      // 初期データ取得
      this.getOfficeModule();
      this.explanation =
        "ご利用になるCIRRUSモジュールを業務形態に合わせ選択してください。\r\nWH / WMS基本は必須、以外は任意です。";
      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * 初期データ取得
     */
    getOfficeModule() {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // パラメータ設定
      config.params.officeCd = this.officeCd; // 営業所コード
      config.params.clientCd = this.clientCd; // 取引先コード

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.INI_OFFICE_MODULE_INFO, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            console.debug("getOfficeModule() Response", response); // 成功
            const list = [];
            if (jsonData.resCom.resComCode == "000") {
              // リストを取得
              jsonData.resIdv.officeModuleInfoList.forEach((row) => {
                list.push({
                  moduleCd: row.moduleCd, // モジュールコード
                  completeFlg: row.completeFlg, // 完了フラグ
                  workFlg: row.workFlg, // 作業中フラグ
                });
              });

              this.inputList = list;
              this.workModuleMap = new Map();
              this.compModuleMap = new Map();
              for (var i = 0; i < this.inputList.length; i++) {
                // モジュール選択ボタンの制御
                if (this.inputList[i].moduleCd != "WH") {
                  this.changeColor(this.inputList[i].moduleCd);
                }
                this.workModuleMap.set(this.inputList[i].moduleCd, this.inputList[i].workFlg);
                this.compModuleMap.set(this.inputList[i].moduleCd, this.inputList[i].completeFlg);
              }
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              // タスクを終了します（失敗）。
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * モジュール選択時処理
     */
    selectModule(id) {
      if (this.compModuleMap.has(id)) {
        if (this.compModuleMap.get(id) == "1") {
          return;
        }
      }
      if (this.workModuleMap.has(id)) {
        // 選択済モジュールでマスタ登録未完了かつマスタ登録中でない場合
        if (this.workModuleMap.get(id) == "0") {
          if (this.delModule.includes(id)) {
            this.delModule = this.delModule.filter(function (element) {
              return element != id;
            });
          } else {
            this.delModule.push(id);
          }
          // 色変更
          this.changeColor(id);
        } else {
          //
        }
      } else {
        // 新規追加の場合
        if (this.addModule.includes(id)) {
          this.addModule = this.addModule.filter(function (element) {
            return element != id;
          });
        } else {
          this.addModule.push(id);
        }
        // 色変更
        this.changeColor(id);
      }
    },

    /**
     * モジュール選択ボタン色変更処理
     */
    changeColor(id) {
      const button = document.getElementsByClassName("module-select");
      button[id].classList.toggle("color");
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     *  戻るボタン押下処理
     */
    returnIniHome() {
      this.$router.push({ name: appConfig.MENU_ID.M_INI_CLIENT_LIST });
    },

    /**
     *  次へボタン押下処理
     */
    pushNext() {
      if (!this.workModuleMap.has("WH")) {
        this.addModule.push("WH");
      }
      if (this.addModule.length == 0 && this.delModule.length == 0) {
        this.moveMasterRegist();
      } else {
        // モジュール登録
        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_INI_002; // 画面ID
        // パラメータ設定
        body.reqIdv.officeCd = this.officeCd; //営業所コード
        body.reqIdv.clientCd = this.clientCd; //取引先コード
        body.reqIdv.moduleCdList = this.addModule; //モジュールコードリスト
        body.reqIdv.deleteModuleCdList = this.delModule; //削除モジュールコードリスト

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.INI_OFFICE_MODULE_ADD, body, appConfig.APP_CONFIG)

            .then((response) => {
              console.debug("pushNext() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                //マスタ登録状況画面へ遷移
                this.moveMasterRegist();

                // エラー時
              } else {
                reject(new Error(jsonData.resCom.resComMessage));
              }
            })
            .catch((ex) => {
              reject(ex);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        }).catch((ex) => {
          this.infoDialog.message = ex.message;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        });
      }
    },

    /**
     *  マスタ登録画面遷移処理
     */
    moveMasterRegist() {
      this.$router.push({
        name: appConfig.MENU_ID.M_INI_MASTER_REGIST,
        params: {
          // 取引先コード
          clientCd: this.clientCd,
        },
      });
    },
    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
  },

  mounted() {
    this.init();
  },
  computed: {},
  watch: {
    //eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.serch-textbox-arrivalNo ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 153px;
}
#listData.v-data-table ::v-deep .v-data-table__wrapper {
  max-height: 500px;
  height: 100%;
}
.v-btn.v-btn--has-bg {
  &.module-select {
    float: left;
    width: 13rem;
    height: 7rem;
    margin-left: 35px;
    font-weight: bold;
    font-size: x-large;
  }

  &.module-select.color {
    background-color: #00eeff;
  }
}

.textbox {
  width: 11.2rem;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.span1 {
  width: 4.3rem;
}
.span2 {
  width: 8rem;
}
a {
  text-decoration: underline;
}

.date-style {
  width: 11rem;
}
#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 8px;
}
</style>
